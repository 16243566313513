import { defineI18nConfig } from '#i18n';

export default defineI18nConfig(() => ({
    pluralRules: {
        pl: polishPluralization,
        cz: polishPluralization,
    },
}))

function polishPluralization(choice: number, choicesLength: number) {
    // np: zero jabłek
    if (choice === 0) {
        return 0;
    }

    // np: jedno jabłko
    if (choice === 1) {
        return 1;
    }

    if (choice < 10 || choice > 20) {
        // np: 3 jabłka lub 23 jabłka
        const lastDigit = choice % 10;
        if (lastDigit >= 2 && lastDigit <= 4) {
            return 2;
        }
    }

    // np 8 jabłek
    return choicesLength < 4 ? 2 : 3;
}
